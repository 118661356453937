var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.countries.length > 0 && _vm.provinces.length > 0)?_c('div',[_c('validation-observer',{ref:"createClientForm"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.createClient.apply(null, arguments)}}},[_c('b-container',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"client-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client-name","state":errors.length > 0 ? false : null,"name":"name"},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2350415432)})],1),_c('b-form-group',{attrs:{"label":"Tipo identificación","label-for":"client-identification-type"}},[_c('validation-provider',{attrs:{"name":"Tipo identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"client-identification-type","options":_vm.identificationTypes,"value-field":"value","text-field":"text"},model:{value:(_vm.client.identification_type),callback:function ($$v) {_vm.$set(_vm.client, "identification_type", $$v)},expression:"client.identification_type"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,193319249)})],1),_c('b-form-group',{attrs:{"label":"Identificación","label-for":"client-identification"}},[_c('validation-provider',{attrs:{"name":"Identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client-identification","state":errors.length > 0 ? false : null,"name":"client-identification"},model:{value:(_vm.client.identification),callback:function ($$v) {_vm.$set(_vm.client, "identification", $$v)},expression:"client.identification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1686786417)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"client-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client-email","state":errors.length > 0 ? false : null,"name":"client-email"},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1740491895)})],1),_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"client-phone"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client-phone","state":errors.length > 0 ? false : null,"name":"client-phone"},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3719497319)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"País","label-for":"client-country"}},[_c('validation-provider',{attrs:{"name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"client-country","options":_vm.countries,"value-field":"id","text-field":"name"},model:{value:(_vm.client.country),callback:function ($$v) {_vm.$set(_vm.client, "country", $$v)},expression:"client.country"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1147665616)})],1),(this.client.country === 60)?_c('div',[_c('b-form-group',{attrs:{"label":"Provincia","label-for":"client-province"}},[_c('validation-provider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"client-province","text-field":"name","value-field":"id","options":_vm.provinces},on:{"change":function($event){return _vm.onChangeProvince($event)}},model:{value:(_vm.client.province),callback:function ($$v) {_vm.$set(_vm.client, "province", $$v)},expression:"client.province"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1422616681)})],1),_c('b-form-group',{attrs:{"label":"Cantón","label-for":"client-canton"}},[_c('validation-provider',{attrs:{"name":"Cantón","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"client-canton","text-field":"name","value-field":"number","options":_vm.cantons},on:{"change":function($event){return _vm.onChangeCanton($event)}},model:{value:(_vm.client.canton),callback:function ($$v) {_vm.$set(_vm.client, "canton", $$v)},expression:"client.canton"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3057330855)})],1),_c('b-form-group',{attrs:{"label":"Distrito","label-for":"client-district"}},[_c('validation-provider',{attrs:{"name":"Distrito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"client-district","text-field":"name","value-field":"number","options":_vm.districts},on:{"change":function($event){return _vm.onChangeDistrict($event)}},model:{value:(_vm.client.district),callback:function ($$v) {_vm.$set(_vm.client, "district", $$v)},expression:"client.district"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3871708039)})],1),_c('b-form-group',{attrs:{"label":"Barrio","label-for":"client-barrio"}},[_c('validation-provider',{attrs:{"name":"Barrio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-select',{attrs:{"id":"client-barrio","options":_vm.barrios,"value-field":"id","text-field":"name"},model:{value:(_vm.client.barrio),callback:function ($$v) {_vm.$set(_vm.client, "barrio", $$v)},expression:"client.barrio"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0] && touched),expression:"errors[0] && touched"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3507633576)})],1)],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-between align-items-center border-top mt-2 pt-2"},[_c('h4',[_vm._v("Contactos")]),_c('b-button',{staticClass:"px-1",attrs:{"variant":"primary"},on:{"click":_vm.addContact}},[_c('feather-icon',{attrs:{"size":"1.2x","icon":"PlusIcon"}})],1)],1)])],1),_c('b-row',_vm._l((_vm.contacts),function(c,index){return _c('b-col',{key:index,attrs:{"md":"6"}},[_c('b-card',[_c('feather-icon',{staticClass:"float-right text-danger cursor-pointer",attrs:{"icon":"XCircleIcon","size":"1.2x"},on:{"click":function($event){return _vm.removeContact(index)}}}),_c('b-form-group',{attrs:{"label":"Nombre","label-for":"client-code"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":_vm.contacts[index].email ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.contacts[index].name),callback:function ($$v) {_vm.$set(_vm.contacts[index], "name", $$v)},expression:"contacts[index].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"client-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":_vm.contacts[index].name ? 'required|email' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.contacts[index].email),callback:function ($$v) {_vm.$set(_vm.contacts[index], "email", $$v)},expression:"contacts[index].email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center border-top align-items-center py-5"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Guardar")])],1)])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }